<template>
  <transition>
    <use-focus-trap
      v-if="modalShown"
      class="default-modal"
      role="dialog"
      :options="{immediate: true}"
    >
      <div
        aria-hidden="true"
        class="default-modal__bg"
        :class="{'default-modal__bg-clear': isBg}"
        @click="modalShown = false"
      ></div>
      <div class="default-modal__wrapper">
        <slot></slot>
        <a href="#" class="default-modal__close hovered" @click.prevent="modalShown = false"
          ><close-svg></close-svg
        ></a>
      </div>
    </use-focus-trap>
  </transition>
</template>

<script setup lang="ts">
import 'sass/base/modal.sass';

import {UseFocusTrap} from '@vueuse/integrations/useFocusTrap/component';
import {computed, watch} from '#imports';
import {useBodyScrollLock} from '@/composables/useScrollLock';
import CloseSvg from 'svg/close.vue';

const props = defineProps({
  modelValue: {type: Boolean, required: true},
  isBg: {type: Boolean, required: false, default: false},
});

const emit = defineEmits<{
  (e: 'update:modelValue', isShown: boolean): void;
}>();

const modalShown = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const {locked: bodyScrollLocked} = useBodyScrollLock();
watch(
  modalShown,
  () => {
    bodyScrollLocked.value = modalShown.value;
  },
  {immediate: true},
);
</script>
