import {onMounted, ref, watch} from '#imports';

let scrollPosition = 0;

const useScrollLock = {
  enable(body: HTMLBodyElement, doNotSaveScrollPosition = false) {
    const {scrollY} = window;
    if (!doNotSaveScrollPosition) {
      // TODO get rid of this
      scrollPosition = scrollY;
    }
    body.style.width = '100%';
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}px`;
    return scrollY;
  },
  disable(body: HTMLBodyElement, savedScrollPosition?: number) {
    body.style.removeProperty('position');
    body.style.removeProperty('top');
    body.style.removeProperty('width');
    window.scrollTo(0, savedScrollPosition ?? scrollPosition);
  },
};

export default useScrollLock;

export const useBodyScrollLock = (initialValue = true) => {
  const body = ref<HTMLBodyElement | null>();
  const savedScrollPosition = ref<number>(0);
  onMounted(() => {
    body.value = document.querySelector('body');
  });

  const locked = ref(initialValue);

  watch(
    locked,
    () => {
      if (body.value) {
        if (locked.value) {
          savedScrollPosition.value = useScrollLock.enable(body.value, true);
        } else {
          useScrollLock.disable(body.value, savedScrollPosition.value);
        }
      }
    },
    {immediate: true},
  );

  return {
    locked,
  };
};
